#sponsors-bg {
    background-image: url("../../assets/images/Ice-bg.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 2%;
}
#sponsors-bg p {
    margin: 0;
}
.sponsor-msg {
    padding-top: 4.5rem;
    font-family:  -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    margin-bottom: -0.5em;   
    font-style: italic;
    position: relative;
}

.sponsor-images img { 
    width: 400px;
    padding: 2em;   
} 

.sponsor-images {
    margin-top: 5em;     
}
  
.sponsor-msg::after {

    content: '';
    width: 50%;
    background-color: black;
    height: 2px;
    display: inline-block;  
}   

/* Change the colour of the emails to make it more clear */
#emailColour {
    color:rgb(65, 128, 245);
}

/* // .disabled
// {
//     text-align: left;
// }

// .nav-link
// {
//     text-align: left;
// }        */

