#rules {
  /* padding-top: 0.5rem; */
}
.listModule {
  width: 80%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
}

.listCustom {
  margin-left: 0;
  list-style: none;
  counter-reset: counter;
}
.listCustom li::before {
  counter-increment: counter;
  content: counter(counter);
}

.listNumbered li {
  position: relative;
  margin-bottom: 2rem;
  border: 3px solid #7d0000;
  padding: 0.75rem;
  border-radius: 10px;
}
.listNumbered li::before {
  font-size: 1.6rem;
  position: absolute;
  top: -1.35rem;
  padding-left: 0.375rem;
  padding-right: 0.375rem;
  color: #7d0000;
  background: #ffffff;
}

.listNumbered li:last-child {
  margin-bottom: 0;
}

/* a push down */

h1 {
  position: relative;
  padding: 0;
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-weight: 300;
  font-size: 40px;
  color: #080808;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

h1 span {
  display: block;
  font-size: 0.5em;
  line-height: 1.3;
}

h1 em {
  font-style: normal;
  font-weight: 600;
}

.heading h1 {
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 5px;
}

.heading h1:before {
  width: 28px;
  height: 5px;
  display: block;
  content: "";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -14px;
  background-color: #b80000;
}
.heading h1:after {
  width: 100px;
  height: 1px;
  display: block;
  content: "";
  position: relative;
  margin-top: 25px;
  left: 50%;
  margin-left: -50px;
  background-color: #b80000;
}
