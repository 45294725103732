.content-section{
    margin-top: 40px;
}

/* #homepageContent {
    background-image: url("../../assets/images/ice-background.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */
/* #homepageContent {
    background-color: white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}  */

#homepageContent p {
    font-size: large;
    font-weight: 600
}

.annualIts{
    color: red;
}

#font-red{
    color: red;
}