.bg {
  background-image: url("../../assets/images/puck.jpg");
  background-blend-mode: multiply;
  height: 100vh;
  min-height: 10%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark), var(--bs-bg-opacity)) !important;
}

.someImage {
  position: absolute;
  width: 100%;
  height: 90%;
  max-width: 325px;
  max-height: 345px;
  margin: auto;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
}

@media only screen and (min-width: 600px) {
  .someImage {
    max-width: 625px;
    max-height: 645px;
  }
}
@media screen and (max-width: 600px) {
  #home {
    max-height: 50vh;
  }
}

#home {
  position: relative;
  top:56px;
}
