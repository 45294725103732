/* .someImage {
  position: absolute;
  width: 100%;
  height: 90%;
  max-width: 325px;
  max-height: 345px;
  margin: auto;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
}

@media only screen and (min-width: 600px) {
  .someImage {
    max-width: 625px;
    max-height: 645px;
  }
} */

#sponsorsCarousel-platinum {
  padding-top: 1.5rem;
  height: 11em;
  /* text-align: center; */
  /* vertical-align: middle; */
}

#sponsorsCarousel-platinum .carousel-item img {
  /* width: 75%; */
  /* height: 75%; */
  max-width: 10em;
  max-height: 10em;
  margin: auto;
  vertical-align: middle;
  padding-bottom: 4rem;
}

#sponsorsCarousel-other2021 {
  padding-top: 1.5rem;
  height: 11em;
  /* text-align: center; */
  /* vertical-align: middle; */
}

#sponsorsCarousel-other2021 .carousel-item img {
  /* width: 100%; */
  /* height: 100%; */
  max-width: 10em;
  max-height: 10em;
  margin: auto;
  vertical-align: middle;
  padding-bottom: 3rem;
}
