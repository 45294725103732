/* Bootstrap Modal */
.modal-dialog {
    min-height: -webkit-calc(100vh - 60px);
    min-height: -moz-calc(100vh - 60px);
    min-height: calc(100vh - 60px);
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    /* overflow: auto;  */
  }

  @media (max-width: 768px) {
    .modal-dialog {
      min-height: -webkit-calc(100vh - 20px);
      min-height: -moz-calc(100vh - 20px);
      min-height: calc(100vh - 20px);   
    }
  }

  #activateButton:hover{
    filter: brightness(85%);
  background:rgba(0,0,0,0.3);
  }


  button.collectBtn {

    background:#7d0000; 
    border-color:#7d0000; 
    color:white; 
  }


  #collectButton{
    cursor: not-allowed;
  }

#collectUnlocked{
    cursor: pointer;   
    -webkit-animation: glowing 1500ms infinite;
    -moz-animation: glowing 1500ms infinite;
    -o-animation: glowing 1500ms infinite;
    animation: glowing 1500ms infinite;
}

@keyframes glowing {
  0% { background-color: #7d0000; -webkit-box-shadow: 0 0 3px #7d0000; }
  50% { background-color: #FF0000; -webkit-box-shadow: 0 0 40px #FF0000; }
  100% { background-color: #7d0000; -webkit-box-shadow: 0 0 3px #7d0000; }
}
  .successIcon{
    color:white; 
    /* // color:lightgreen;  
    // display: none; */
  }
  #error{
    /* // display: none;  */
    color: red;
  }
  .activationContainer{
    width:100%; 
    height:100%; 
    display: table;
    text-align: center;
  }
  .activationContent{
    display: table-cell; 
    vertical-align: middle; 
    text-align: center;
  }
 
  button.checking-code-button {                
    background: #6c757d;
    border-color: #6c757d;
  }

  button.correct-code-button {
    background: rgb(108,182,108);
    border-color: rgb(108,182,108);
  }

  button.normal-button {
    background: #ffffff;
    border-color: #7d0000;  
  }