.content-section {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
}

/*#about {
    background-image: url("../../assets/images/Ice-bg.jpg");
    background-position: center;
    background-repeat: repeat-y;
    background-size: cover;
}*/

.paragraphs{
  padding: 5%;
  background-color: #eeeeee;
  border-radius: 10px;
  border-style: solid;
  border-color: maroon;
}

@media(max-width: 966px)
{
  h1.about
  {
    font-size: 3em !important;       
  }
}


#about p{
  font-weight: 600;
  font-size: large;
}
  