/* #parallax {
background: url("../../assets/images/ice.jpg") no-repeat center center fixed;
background-attachment: fixed;
display: table;
height: 100%;
position: relative;
width: 100%;
background-size: cover;
} */

#whyThisPool{
  font-size: 2.5rem;
}
#puck-tastic {
  word-break: keep-all;
  display: inherit;
  font-size: inherit;
  line-height: inherit;
}

#intro{
  font-weight: 800 !important;
}

.white-text {
  color: #fff;
}

.content-section {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}

.details{
  margin-top: 5%;
  padding: 5%;
  background-color: #eeeeee;
  border-radius: 10px;
  border-style: solid;
  border-color: maroon;
}

.disabled {
  cursor: not-allowed;
}

@media screen and (max-width: 991px) {
  #parallax h1 {
    font-size: 1.5em !important;
    font-weight: 700;
  }

  #parallax h4 {
    font-size: 1.2em !important;
    font-weight: 800;
  }
}

.btnWidth{
  width: 50%;
}

#details p {
  font-weight: 600;
  font-size: large;
}