/* .prizeUI {
    margin: auto;
    display: flex;
    flex-direction: column;
     background-color: rgba(255, 255, 255, 0.5);
     border-radius: 40px; 
    width: 95%;
    align-items: center;
    padding: 2.5% 0;
    padding-bottom: 5%;
    font-family: "Lato";
    max-height: 100%;
    min-height: 500px;
} */
/* .prizeUI form {
    display: flex;
    flex-direction: column;
    width: 60%;
} */
/* .prizeUI h1 {
    font-size: 30px;
    font-family: "Lato", sans-serif;
    font-weight: bold;
    padding-bottom: 5px;
} */
/* .prizeUI label {
    color: white;
    font-weight: 800;
} */
.prizeCard {
    flex: 0 0 25%;
    background: white;
    border: 2px solid #a2a2a2;
    border-radius: 4px;
    padding: 10px;
    margin: 2px;
    height: 100%;
    position: relative;
    transition: all 0.75s ease-in-out 0s;
}
.UIcard {
    border: white;
}

.prizes-desc, .upload, .update, .delete {
    margin: 5px;
}

.update {
    cursor: pointer;
}

@media only screen and (max-width: 850px) {
    .prizeCard {
        flex: none !important;
        max-width: 75% !important;
    }
}