.not-found-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    padding: 0 20px;
    background-color: #f0f2f5; /* Light background for a soft look */
    margin-top: 3%;
}
 
.not-found-page .content {
    max-width: 560px;
}
 
.not-found-page h1 {
    font-size: 2.5rem;
    color: #7d0000;
}
 
.not-found-page h2 {
    font-size: 1.75rem;
    color: black; 
    font-weight: bolder;
    
}
 
.not-found-page p {
    margin-top: 20px;
    margin-bottom: 30px;
    color: #333; 
}
 
.home-button {
    padding: 10px 30px;
    background-color: #7d0000; 
    color: #fff;
    border-radius: 5px;
    text-decoration: none
}

.content{
    margin-top: 8%;
}

body, html {
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
}
