 #login-container a {
	 font-family: 'Ubuntu', sans-serif;
	 font-size: 14px;
	 line-height: 1.7;
	 color: #666;
	 margin: 0px;
	 transition: all 0.4s;
	 -webkit-transition: all 0.4s;
	 -o-transition: all 0.4s;
	 -moz-transition: all 0.4s;
}
 #login-container a:focus {
	 outline: none !important;
}
 #login-container a:hover {
	 text-decoration: none;
	 color: #1b3815;
}
 #login-container h1, #login-container h2, #login-container h3, #login-container h4, #login-container h5, #login-container h6 {
	 margin: 0px;
}
 #login-container p {
	 font-family: 'Ubuntu', sans-serif;
	 font-size: 14px;
	 line-height: 1.7;
	 color: #666;
	 margin: 0px;
}
 #login-container ul, #login-container li {
	 margin: 0px;
	 list-style-type: none;
}
 #login-container input {
	 outline: none;
	 border: none;
}
 #login-container input[type="number"] {
	 -moz-appearance: textfield;
	 appearance: none;
	 -webkit-appearance: none;
}
 #login-container input[type="number"]::-webkit-outer-spin-button, #login-container input[type="number"]::-webkit-inner-spin-button {
	 -webkit-appearance: none;
}
 #login-container textarea {
	 outline: none;
	 border: none;
}
 #login-container textarea:focus, #login-container input:focus {
	 border-color: transparent !important;
}
 #login-container input::-webkit-input-placeholder {
	 color: #1b3815;
}
 #login-container input:-moz-placeholder {
	 color: #1b3815;
}
 #login-container input::-moz-placeholder {
	 color: #1b3815;
}
 #login-container input:-ms-input-placeholder {
	 color: #1b3815;
}
 #login-container textarea::-webkit-input-placeholder {
	 color: #1b3815;
}
 #login-container textarea:-moz-placeholder {
	 color: #1b3815;
}
 #login-container textarea::-moz-placeholder {
	 color: #1b3815;
}
 #login-container textarea:-ms-input-placeholder {
	 color: #1b3815;
}
 #login-container button {
	 outline: none !important;
	 border: none;
	 background: transparent;
}
 #login-container button:hover {
	 cursor: pointer;
}
 #login-container iframe {
	 border: none !important;
}
 #login-container .txt1 {
	 font-family: 'Ubuntu', sans-serif;
	 font-size: 15px;
	 color: #999;
	 line-height: 1.4;
}
 #login-container .txt2 {
	 font-family: 'Ubuntu', sans-serif;
	 font-size: 15px;
	 color: #57b846;
	 line-height: 1.4;
}
 #login-container .txt3 {
	 font-family: 'Ubuntu', sans-serif;
	 font-size: 15px;
	 color: #57b846;
	 line-height: 1.4;
	 text-transform: uppercase;
}
 #login-container .limiter {
	 width: 100%;
	 margin: 0 auto;
}
 #login-container .container-login100 {
	 width: 100%;
	 min-height: 100vh;
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -moz-box;
	 display: -ms-flexbox;
	 display: flex;
	 flex-direction: column;
	 flex-wrap: wrap;
	 justify-content: center;
	 align-items: center;
	 padding: 15px;
	 position: relative;
	 background-color: lightslategray;
}
 #login-container .wrap-login100 {
	 width: 500px;
	 background: #343a40;
	 border-radius: 20px;
	 overflow: hidden;
	 box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
	 -moz-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
	 -webkit-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
	 -o-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
	 -ms-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
}
 @media only screen and (max-width: 515px) {
	 #login-container .wrap-login100 {
		 width: auto;
	}
}
 #login-container .login100-form {
	 width: 100%;
	 position: relative;
}
 #login-container .login100-form-title {
	 font-family: 'Ubuntu', sans-serif;
	 font-size: 30px;
	 color: #fff;
	 line-height: 1.2;
	 text-align: center;
	 display: block;
	 position: absolute;
	 width: 100%;
	 top: 0;
	 left: 0;
	 background-color: #7d0000;
	 text-transform: uppercase;
	 padding-top: 50px;
	 padding-bottom: 39px;
}
 #login-container .wrap-input100 {
	 width: 100%;
	 background-color: #fff;
	 border-radius: 27px;
	 position: relative;
	 z-index: 1;
}
 #login-container .input100 {
	 font-family: 'Ubuntu', sans-serif;
	 font-size: 15px;
	 color: #1b3815;
	 line-height: 1.2;
	 position: relative;
	 display: block;
	 width: 100%;
	 height: 55px;
	 background: #ebebeb;
	 border-radius: 27px;
	 padding: 0 35px 0 35px;
}
 #login-container #displayPassword {
	 padding: 5px;
	 font-size: 30px;
	 float: right;
	 margin-right: 3px;
	 margin-top: -42px;
	 position: relative;
	 z-index: 2;
}
 #login-container input:required {
	 box-shadow: none;
}
 #login-container input::-ms-clear, #login-container input::-ms-reveal {
	 display: none;
}
 #login-container .focus-input100 {
	 display: block;
	 position: absolute;
	 z-index: -1;
	 width: 100%;
	 height: 100%;
	 top: 0;
	 left: 50%;
	 -webkit-transform: translateX(-50%);
	 -moz-transform: translateX(-50%);
	 -ms-transform: translateX(-50%);
	 -o-transform: translateX(-50%);
	 transform: translateX(-50%);
	 border-radius: 31px;
	 background-color: #ebebeb;
	 pointer-events: none;
	 -webkit-transition: all 0.4s;
	 -o-transition: all 0.4s;
	 -moz-transition: all 0.4s;
	 transition: all 0.4s;
}
 #login-container .input100:focus + .focus-input100 {
	 width: calc(100% + 20px);
}
 #login-container .container-login100-form-btn {
	 width: 100%;
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -moz-box;
	 display: -ms-flexbox;
	 display: flex;
	 flex-wrap: wrap;
	 justify-content: center;
}
 #login-container .container-login100-form-btn .alert {
	 border-radius: 35px;
	 visibility: hidden;
	 font-size: 90%;
}
 #login-container .login100-form-btn {
	 display: -webkit-box;
	 display: -webkit-flex;
	 display: -moz-box;
	 display: -ms-flexbox;
	 display: flex;
	 justify-content: center;
	 align-items: center;
	 padding: 0 20px;
	 width: 100%;
	 height: 50px;
	 background-color: #7d0000;
	 border-radius: 25px;
	 font-family: 'Ubuntu', sans-serif;
	 font-size: 15px;
	 color: #fff;
	 line-height: 1.2;
	 text-transform: uppercase;
	 -webkit-transition: all 0.4s;
	 -o-transition: all 0.4s;
	 -moz-transition: all 0.4s;
	 transition: all 0.4s;
}
 #login-container .login100-form-btn:hover {
	 color: #343a40;
}
 #login-container .validate-input {
	 position: relative;
}
 #login-container .alert-validate::before {
	 content: attr(data-validate);
	 position: absolute;
	 z-index: 1000;
	 max-width: 70%;
	 background-color: #fff;
	 border: 1px solid #c80000;
	 border-radius: 14px;
	 padding: 4px 25px 4px 10px;
	 top: 50%;
	 -webkit-transform: translateY(-50%);
	 -moz-transform: translateY(-50%);
	 -ms-transform: translateY(-50%);
	 -o-transform: translateY(-50%);
	 transform: translateY(-50%);
	 right: 10px;
	 pointer-events: none;
	 font-family: 'Ubuntu', sans-serif;
	 color: #c80000;
	 font-size: 13px;
	 line-height: 1.4;
	 text-align: left;
	 visibility: hidden;
	 opacity: 0;
	 -webkit-transition: opacity 0.4s;
	 -o-transition: opacity 0.4s;
	 -moz-transition: opacity 0.4s;
	 transition: opacity 0.4s;
}
 #login-container .alert-validate::after {
	 content: "\f06a";
	 font-family: 'Ubuntu', sans-serif;
	 display: block;
	 position: absolute;
	 z-index: 1100;
	 color: #c80000;
	 font-size: 16px;
	 top: 50%;
	 -webkit-transform: translateY(-50%);
	 -moz-transform: translateY(-50%);
	 -ms-transform: translateY(-50%);
	 -o-transform: translateY(-50%);
	 transform: translateY(-50%);
	 right: 16px;
}
 #login-container .alert-validate:hover:before {
	 visibility: visible;
	 opacity: 1;
}
 @media (max-width: 992px) {
	 #login-container .alert-validate::before {
		 visibility: visible;
		 opacity: 1;
	}
}
 @media (max-width: 576px) {
	 #login-container .login100-form {
		 padding-left: 15px;
		 padding-right: 15px;
	}
}
 