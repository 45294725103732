.main-content{
    margin-top: 50px;
}

.form-content{
    margin-top:60px;
    text-align: left;
}

.form-labels{
    display: flex;
}

form{
    border-radius: 8px;
    background-color: #f2f2f2;
    padding: 20px;
}

.form-button {
    margin: auto;
    width: 100%;
    font-size: 18px;
    background-color: rgb(30, 154, 236) !important;
    letter-spacing: 2px;
    border: None;
}

.form-button:hover {
    background-color: rgb(84, 173, 32) !important;
}

/* Below is css for the listing of sponsors */
.list-sponsors h3{
    margin-bottom: 20px;
    text-decoration: underline;
    font-weight: bold;
    text-align: left;
    font-size: 2rem;
}

#deleteButton{
    color: rgb(255, 255, 255);
    background-color: rgb(52, 139, 253);
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 3px;
    /*margin-left: 5px; */
    width: 45%;
}

#updateButton{
    color: rgb(255, 255, 255);
    background-color: rgb(255, 115, 0);
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 3px;
    /*margin-left: 5px; */
    width: 45%;
}

#updateButton:hover{
    background-color: rgb(238, 81, 81);
}

#deleteButton:hover{
    background-color: rgb(238, 81, 81);
}

#toShow{
    color: rgb(255, 255, 255);
    background-color: rgb(84, 173, 32);
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 3px;
    /*margin-left: 5px; */
    width: 45%;
}

#toShow:hover , #toHide:hover{
    background-color: rgb(238, 81, 81);
}

#toHide{
    color: rgb(255, 255, 255);
    background-color: rgb(245, 166, 21); 
    font-weight: bold;
    letter-spacing: 1px;
    margin-bottom: 3px;
    /*margin-left: 5px; */
    width: 45%;
}

/* Add a border so that all sponsor images' border are the same size so that the delete button can all be at the same place. */
.card{
    width: 100%;
    height: auto;
}

.card img{
    height: 25%;
    width:  25%;
}

.card-body p{
    font-size: small;
}

#second-title {
    padding-top: 20px;
}

@media screen and  (max-width: 1200px){

    #deleteButton{
        color: rgb(255, 255, 255);
        background-color: rgb(52, 139, 253);
        font-weight: bold;
        letter-spacing: 1px;
        margin-left: auto;
        width:100%;
    }
    
    #toShow{
        color: rgb(255, 255, 255);
        background-color: rgb(84, 173, 32);
        font-weight: bold;
        letter-spacing: 1px;
        width:100%;
    }

    #toHide{
        color: rgb(255, 255, 255);
        background-color: rgb(245, 166, 21); 
        font-weight: bold;
        letter-spacing: 1px;
        width:100%;
    }
}
