/* .someImage {
  position: absolute;
  width: 100%;
  height: 90%;
  max-width: 325px;
  max-height: 345px;
  margin: auto;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
}

@media only screen and (min-width: 600px) {
  .someImage {
    max-width: 625px;
    max-height: 645px;
  }
} */

#slideshowCarousel {
  padding-top: 1.5rem;
  height: 95vh;
  /* text-align: center;
  vertical-align: middle; */
}

#slideshowCarousel .carousel-item img {
  width: 100%;
  height: 100%;
  max-width: 125vh;
  max-height: 85vh;
  min-height: 25%;
  margin: auto;
  object-fit: contain;
  vertical-align: middle;
  padding-bottom: 3rem;
}
/* Change the look of the slide indicator made it look cleaner */
.carousel-indicators button{
  width: 10px !important;
  height: 10px !important;
  margin-left: 20px !important;
  border-radius: 50%;
}

.carousel-indicators .active{
  background-color: black !important;
}


@media screen and (max-width: 600px) {
  #slideshowCarousel {
    max-height: 50vh;
  }
}
