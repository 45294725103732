#admin-container {
    padding-top: 70px;
}
#admin-container [disabled] {
    background: #888686;
    border: 0;
    outline: 0;
    width: 100%;
    padding: 8px;
    color: #fff;
    cursor: not-allowed;
}
#admin-container #registration {
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: rgba(125, 0, 0, 0.75);
    border-radius: 40px;
    width: 75%;
    align-items: center;
    padding: 2.5% 0 5% 0;
    font-family: "Lato";
    max-height: 350px;
    min-height: 100%;
    /* color: white; */
}
#admin-container #registration h1 {
    text-align: center;
    font-family: "Lato", sans-serif;
    font-weight: bold;
    /* color: white; */
    font-size: 30px;
}
#admin-container #registration form {
    display: flex;
    flex-direction: column;
    height: 20vh;
    width: 60%;
}
#admin-container #registration form label {
    margin: 5% 0 0;
    font-weight: 800;
}
#admin-container #registration form .register-btn {
    justify-content: center;
    align-items: center;
    /* margin: 10% 0; */
}
#admin-container #registration form #statusButton {
    margin-left: 10px;
}
#admin-container #logout {
    padding: 10px 10px;
}

#admin-container #upload-winners {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0;
    padding-bottom: 50px;
    font-family: "Lato";
    font-weight: bold;
    margin-bottom: 20px;
}

.custom-file-upload {
    background-color: rgba(148, 159, 176,0.5);
    border-radius: 10px;
    display: inline-block;
    padding: 6px 12px;
}

#successMessage{
    padding-top: 20px;
}

.excelTable{
    background-color: rgba(148, 159, 176,0.5);
    margin: 20px;
    min-width: 60vw;
}

.excelTable td{
    padding: 10px;
}

.excelTable tr{
    border-bottom: solid, ;
}

.prize-ui {
    padding: 20px 0;
}
.logout-btn {
    padding: 10px;
}