.positiveScore{
    background-color: #9edeaf;
}

.negativeScore{
    background-color: #db9e9c;
}

.scoringContent{
    margin-top: 72px;
}

h1 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 40px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

h1 span {
    display: block;
    font-size: 0.5em;
    line-height: 1.3;
}
  
h1 em {
    font-style: normal;
    font-weight: 600;
}
  
h1 {
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 5px;
}

.icons{
    width: 25px;
    vertical-align: middle;
    margin-right: 20px;
}

.iconText{
    font-weight: 400;
    font-size: xx-large;
    vertical-align:middle;
    display:inline;
}

thead{
    border-bottom:1px solid red;
}

.card{
    margin-bottom: 20px;
}

.cardDiv{
    padding-left: 10px;
    padding-right: 10px;
}

th{
    font-weight: 600;
}