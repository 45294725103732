.red-background-navbar {
    background: #7d0000;
}

.navbar-custom .container .navbar-brand {
    color: white;
}

.navbar-custom .container .navbar-nav .nav-link {
    color: white;
}

.navbar-custom .container .navbar-nav .nav-link.selected {
    color: black;
}

.navbar-custom .container .navbar-nav .nav-link:focus {
    outline: none;
}

.navbar-custom .container .navbar-nav .nav-link.loggingOut:hover {
    cursor: pointer;
}

.navbar-custom .container .visible {
    display: inline;
}

.navbar-custom .container .invisible {
    display: none;
}

.dropdown-menu .dropdown-item {
    margin-top: -8px;
    margin-bottom: -8px;
    background-color: #7d0000;
}

#navbarDropdown{
    background-color: #7d0000;
}
